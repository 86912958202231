import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import {
  Content,
  Icons,
  Download,
  Cooperation,
} from "page_components/architect-distributor"

const Architect = ({ data }) => {
  const pageData = data?.allWpPage?.nodes[0]

  return (
    <Layout>
      <Breadcrumbs title="Dla architektów" />
      <PageHeader
        title={pageData?.acfArchitectDistributor?.header}
        content={pageData?.acfArchitectDistributor?.subHeader}
      />
      <Content pageData={pageData} />
      <Icons pageData={pageData} />
      <Download pageData={pageData} />
      <Cooperation pageData={pageData} />
    </Layout>
  )
}

export const Head = ({ data }) => (
  <Seo
    title={
      data?.allWpPage?.nodes[0]?.seo?.title
        ? data?.allWpPage?.nodes[0]?.seo?.title
        : data?.allWpPage?.nodes[0]?.title
    }
    description={
      data?.allWpPage?.nodes[0]?.seo?.metaDesc &&
      data?.allWpPage?.nodes[0]?.seo?.metaDesc
    }
  />
)

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDo3NTg2" } }) {
      nodes {
        seo {
          title
          metaDesc
        }
        acfArchitectDistributor {
          header
          subHeader
          content
          contentImage {
            localFile {
              publicURL
            }
          }
          gridHeader
          gridSubHeader
          gridContent {
            icon {
              localFile {
                publicURL
              }
            }
            header
            description
          }
          filesHeader
          filesContent {
            name
            fileExternalUrl
            file {
              localFile {
                publicURL
              }
            }
          }
          contactHeader
          contactSubHeader
          contactDescription
        }
      }
    }
  }
`

export default Architect
